
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import AxiosAdapter from '@/request/AxiosAdapter'
export default defineComponent({
  name: 'pdfPreview', //移动端pdf预览
  components: {},
  setup() {
    const route = useRoute()
    const router = useRouter()
    const pdfViewer = ref()
    const PdfUrl = ref()
    // PdfUrl.value = 'PDF/web/viewer.html?file=http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf'
    const getFileUrl = async () => {
      const urlStr = window.location.href
      const index = urlStr.indexOf('=')
      const id = urlStr.substring(index + 1, urlStr.length)
      await AxiosAdapter.get({
        url: 'FileDetail/getFileDetail/' + id
      })
        .then((res: any) => {
          const filePath: any = process.env.VUE_APP_PROXY_TARGET
          PdfUrl.value =
            '/mm/PDF/web/viewer.html?file=' + filePath + encodeURIComponent(res)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    onMounted(() => {
      getFileUrl()
    })
    return {
      PdfUrl
    }
  }
})
